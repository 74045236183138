import { ApolloError, useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line import/no-named-as-default
import Router, { useRouter } from 'next/router'
import { Box, Container, Stack, Typography } from '@mui/material'
import Head from 'next/head'
import {
  Button,
  GoogleAuth,
  TGoogleCredential,
  DividersBlock,
  TextFieldController,
  PasswordInputController,
} from 'settle-ui'
import { useSnackbars } from 'settle-ui/hooks'
import { ExternalAppLayout } from 'pages/components/ExternalAppLayout/ExternalAppLayout'
import { externalLinks } from 'lib/consts/externalLinks'
import { logEvent } from 'lib/tracking'
import { localStorageKeys } from 'lib/consts/browserStorage'
import { ERouterPage, getBusinessRootPath } from 'lib/navigation'
import { getLSItem } from 'lib/helpers/getLSItem/getLSItem'
import { deleteTokenCookie } from 'lib/helpers/deleteTokenCookie/deleteTokenCookie'
import { useSetTokenCookie } from 'lib/hooks/useSetTokenCookie/useSetTokenCookie'
import { EMAIL_PATTERN } from 'lib/patterns'
import { useBrandName } from 'lib/hooks/useBrandName/useBrandName'
import { LoginPage_authProviderSignInDocument } from 'gql'
import { MetaTags } from '../components/MetaTags/MetaTags'
import { useUrlBasedSnackbar } from '../hooks/useUrlBasedSnackbar/useUrlBasedSnackbar'
import { useExistingVendorWarning } from './hooks/useExistingVendorWarning/useExistingVendorWarning'
import * as events from './LoginPage.events'
import { LoginPage_AuthResponseFragment, LoginPage_signInDocument } from './LoginPage.graphql'
import { CreateAccountLink } from './components/CreateAccountLink/CreateAccountLink'
import { ForgotPasswordLink } from './components/ForgotPasswordLink/ForgotPasswordLink'

export interface ILoginForm {
  email: string
  password: string
}

export const LoginPage = () => {
  useUrlBasedSnackbar()
  useExistingVendorWarning()
  const setTokenCookie = useSetTokenCookie()
  const router = useRouter()
  const brand = useBrandName()
  const next = typeof router.query.next === 'string' ? router.query.next : ''
  const email = typeof router.query.email === 'string' ? router.query.email : ''
  const { t } = useTranslation()
  const { showApolloOrTextError, showError } = useSnackbars()
  const [signIn] = useMutation(LoginPage_signInDocument)
  const [signInGoogle] = useMutation(LoginPage_authProviderSignInDocument)
  const [isLoading, setIsLoading] = useState(false)
  const form = useForm<ILoginForm>({ defaultValues: { email } })
  const formEmail = form.watch('email')
  const { reset } = form

  useEffect(() => {
    deleteTokenCookie()
  }, [])

  useEffect(() => {
    reset({ email })
  }, [email, reset])

  const onSuccess = (memberships?: NonNullable<LoginPage_AuthResponseFragment['profile']>['memberships']) => {
    if (next) {
      return document.location.assign(next)
    }
    const businessId = getLSItem(localStorageKeys.businessId)

    if (!memberships || memberships.length === 0) {
      setIsLoading(false)
      showError(t('noBusinessLoginErrorMessage'))
      return null
    }

    if (businessId) {
      if (memberships?.find((membership) => membership.business?.id === businessId)) {
        return Router.push(getBusinessRootPath(businessId))
      }
    }
    return Router.push(ERouterPage.root)
  }

  const handleGoogleSuccess = async (jwt: string, credential: TGoogleCredential) => {
    try {
      setIsLoading(true)

      const { data } = await signInGoogle({
        variables: {
          data: jwt,
        },
      })

      if (data?.authProviderSignIn?.token) {
        setTokenCookie(data.authProviderSignIn.token)
        onSuccess(data.authProviderSignIn.profile?.memberships)
        setTimeout(() => {
          google.accounts.id.revoke(credential.email)
        }, 1000)
      }
    } catch (err) {
      google.accounts.id.revoke(credential.email)
      setIsLoading(false)
      showApolloOrTextError(err, t('failedToSignIn'))
    }
  }

  const handleSubmit = async (value: ILoginForm) => {
    logEvent(events.LOGIN_BUTTON_CLICK)

    try {
      setIsLoading(true)

      const { data } = await signIn({
        variables: value,
      })

      if (data?.signIn?.token) {
        setTokenCookie(data.signIn.token)
        onSuccess(data.signIn.profile?.memberships)
      } else if (data?.signIn?.mfaToken && data?.signIn?.totpSetupInfo) {
        Router.push(
          {
            pathname: ERouterPage.loginSetup,
            query: {
              email: value.email,
              password: value.password,
            },
          },
          ERouterPage.loginSetup,
        )
      } else if (data?.signIn?.mfaToken) {
        window.localStorage.setItem(localStorageKeys.mfaToken, data.signIn.mfaToken)
        window.localStorage.setItem(localStorageKeys.mfaEmail, value.email)
        Router.push({
          pathname: ERouterPage.loginVerify,
          query: next && {
            next,
          },
        })
      }
    } catch (err) {
      setIsLoading(false)

      if (err instanceof ApolloError) {
        const [error] = err.graphQLErrors

        if (error?.extensions?.code === 'account_locked') {
          showApolloOrTextError(err, t('failedToSignIn'), {
            text: t('contactSupport'),
            onClick: () => (window.location.href = `mailto:${externalLinks.supportEmail}`),
          })
          return
        }
      }

      showApolloOrTextError(err, t('failedToSignIn'), {
        id: 'login-page_apollo_error',
      })
    }
  }

  return (
    <>
      <Head>
        <MetaTags title={brand} description={t('loginPage.meta.description')} />
      </Head>
      <ExternalAppLayout>
        <Container
          maxWidth="xs"
          sx={(theme) => ({
            marginTop: theme.spacing(3),
            [theme.breakpoints.down('md')]: {
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(3),
            },
          })}
        >
          <Typography align="center" variant="h3" color="primary" mt={3} mb={4}>
            {t('login')}
          </Typography>
          <GoogleAuth onSuccess={handleGoogleSuccess} />
          <DividersBlock sx={{ margin: ({ spacing }) => spacing(2, 0, 0) }}>
            <Typography variant="body2" color="textSecondary">
              Or
            </Typography>
          </DividersBlock>
          <form noValidate={true} onSubmit={form.handleSubmit(handleSubmit)} method="post">
            <TextFieldController
              size="medium"
              color="secondary"
              type="email"
              name="email"
              data-test-id="email-field"
              control={form.control}
              label="Email"
              rules={{
                pattern: { value: EMAIL_PATTERN, message: t('emailIsIncorrect') },
                required: { value: true, message: t('emailCantBeEmpty') },
              }}
            />
            <PasswordInputController
              size="medium"
              margin="normal"
              color="secondary"
              name="password"
              data-test-id="password-field"
              control={form.control}
              label="Password"
              rules={{
                required: { value: true, message: t('passwordCantBeEmpty') },
              }}
            />
            <Box sx={{ textAlign: 'center' }}>
              <Button
                type="submit"
                variant="primary"
                loading={isLoading}
                size="large"
                sx={(theme) => ({
                  width: '230px',
                  marginTop: theme.spacing(3),
                  marginBottom: theme.spacing(2),
                  [theme.breakpoints.down('md')]: {
                    width: '100%',
                  },
                })}
              >
                {t('logIn')}
              </Button>
            </Box>
          </form>
          <Stack gap={1} alignItems="center">
            <ForgotPasswordLink email={formEmail} />
            <CreateAccountLink />
          </Stack>
        </Container>
      </ExternalAppLayout>
    </>
  )
}
