import { ComponentProps, forwardRef } from 'react'
import { TextField as MuiTextField } from '@mui/material'
import * as $ from './TextField.styled'

export type TTextFieldProps = ComponentProps<typeof MuiTextField> & {
  warning?: boolean
}

export const TextField = forwardRef<HTMLDivElement, TTextFieldProps>(({ warning, children, ...props }, ref) => (
  <$.TextField {...(warning && { 'data-warning': true, warning })} {...props} ref={ref}>
    {children}
  </$.TextField>
))

TextField.displayName = 'TextField'
