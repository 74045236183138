import { useState, useEffect } from 'react'
import { isSSR } from 'lib/helpers/isSSR/isSSR'
import { marketingLinks } from 'lib/consts/externalLinks'
import { getIsRefererSettleApp } from './helpers/getIsRefererSettleApp/getIsRefererSettleApp'

export const useHomeHref = () => {
  const [homeHref, setHomeHref] = useState<string>(marketingLinks.marketingRoot)
  const isRefererSettleApp = getIsRefererSettleApp(!isSSR() ? window.document.referrer : '')

  useEffect(() => {
    setHomeHref(isRefererSettleApp ? '/' : marketingLinks.marketingRoot)
  }, [isRefererSettleApp])

  return homeHref
}
