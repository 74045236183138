import { useTranslation } from 'react-i18next'
// eslint-disable-next-line import/no-named-as-default
import Router from 'next/router'
import { Link } from 'settle-ui'
import { ERouterPage } from 'lib/navigation'
import { logEvent } from 'lib/tracking'
import * as events from '../../LoginPage.events'

interface IForgotPasswordLinkProps {
  email: string
}

export const ForgotPasswordLink: Settle.FC<IForgotPasswordLinkProps> = ({ email }) => {
  const { t } = useTranslation()

  const handleClick = () => {
    logEvent(events.FORGOT_PASSWORD_LINK_CLICK)
    Router.push({ pathname: ERouterPage.forgotPassword, query: { email } })
  }

  return (
    <Link color="secondary" onClick={handleClick}>
      {t('forgotPassword')}
    </Link>
  )
}
