import { useTranslation } from 'react-i18next'
// eslint-disable-next-line import/no-named-as-default
import Router from 'next/router'
import { useSearchParams } from 'next/navigation'
import { Link } from 'settle-ui'
import { logEvent } from 'lib/tracking'
import * as events from '../../LoginPage.events'
import { payerInvitationRoutes } from 'pages/sign-up/payers/consts'

export const CreateAccountLink = () => {
  const { t } = useTranslation()
  const searchParams = useSearchParams()

  const handleClick = () => {
    logEvent(events.CREATE_ACCOUNT_LINK_CLICK)
    Router.push({
      pathname: payerInvitationRoutes.product,
      search: searchParams.toString(),
    })
  }

  return (
    <Link color="secondary" onClick={handleClick}>
      {t('loginPage.createAccount')}
    </Link>
  )
}
