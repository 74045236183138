import { FormControl as MuiFormControl, styled } from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
import { IconButton } from 'settle-ui/components/IconButton/IconButton'
import { youShallNotPass } from 'lib/helpers/youShallNotPass/youShallNotPass'

interface IFormControlProps {
  isRequirementsVisible: boolean
}
export const FormControl = styled(MuiFormControl, {
  shouldForwardProp: youShallNotPass('isRequirementsVisible'),
})<IFormControlProps>(({ theme, isRequirementsVisible, margin }) => ({
  ...(!isRequirementsVisible &&
    margin !== 'none' && {
      marginBottom: theme.spacing(3),
    }),
}))
export const Input = styled(OutlinedInput)({
  paddingRight: 0,
  backgroundColor: '#fff',
})
export const Button = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
}))
