/* eslint-disable max-len */
export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const WEBSITE_PATTERN = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
export const EIN_TIN_PATTERN = /^[0-9]{9}$/
export const SSN_LAST_FOUR_PATTERN = /^[0-9]{4}$/
export const SSN_PATTERN = /^[0-9]{9}$/
// eslint-disable-next-line no-useless-escape
export const ADDRESS_FIELD_PATTERN = /^[A-Za-z\d\/?:().,'+ -]+$/gm
