import { Box, BoxProps } from '@mui/material'
import React, { forwardRef } from 'react'

export const SettleLogo = forwardRef<HTMLImageElement, BoxProps>((props, ref) => (
  <>
    {(!process.env.BRAND || process.env.BRAND === 'settle') && (
      <Box component="img" src="/assets/settle_v2.svg" width="63px" height="18px" title="Logo" {...props} ref={ref} />
    )}
    {process.env.BRAND === 'escalon' && (
      <Box component="img" src="/assets/escalon_v2.svg" width="161px" height="28px" title="Logo" {...props} ref={ref} />
    )}
  </>
))

SettleLogo.displayName = 'SettleLogo'
