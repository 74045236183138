import { Controller, ControllerProps, FieldValues } from 'react-hook-form'
import { FieldPath } from 'react-hook-form/dist/types'
import { TextField, TTextFieldProps } from 'settle-ui/components/TextField/TextField'
import { mergeRefs } from 'lib/helpers/mergeRefs/mergeRefs'

type TController<T extends FieldValues, TName extends FieldPath<T>> = Pick<
  ControllerProps<T, TName>,
  'defaultValue' | 'shouldUnregister' | 'name' | 'rules' | 'control'
>
export type TTextFieldControllerProps<T extends FieldValues, TName extends FieldPath<T>> = TTextFieldProps &
  TController<T, TName>
export type TTextFieldController = <T extends FieldValues, TName extends FieldPath<T>>(
  props: TTextFieldControllerProps<T, TName>,
) => JSX.Element

export const TextFieldController: TTextFieldController = ({
  id,
  name,
  rules,
  control,
  variant,
  inputRef,
  defaultValue,
  size = 'small',
  shouldUnregister,
  required = true,
  fullWidth = true,
  margin = 'normal',
  color = 'secondary',
  type = 'text',
  ...restTextFieldProps
}) => (
  <Controller
    data-test-id={id}
    defaultValue={defaultValue}
    shouldUnregister={shouldUnregister}
    name={name}
    rules={rules}
    control={control}
    render={({ field: { ref, ...fieldProps }, fieldState: { error } }) => {
      const helperText = error ? error.message : restTextFieldProps.helperText

      return (
        <TextField
          data-test-id={id}
          {...fieldProps}
          {...restTextFieldProps}
          size={size}
          color={color}
          inputRef={mergeRefs([ref, inputRef])}
          margin={margin}
          variant={variant}
          required={required}
          fullWidth={fullWidth}
          helperText={helperText}
          type={type}
          error={Boolean(error) || restTextFieldProps.error}
        />
      )
    }}
  />
)
