import { forwardRef } from 'react'
// eslint-disable-next-line settle/preferred-modules
import type { LinkTypeMap } from '@mui/material'
import type { OverridableComponent } from '@mui/material/OverridableComponent'
import * as $ from './Link.styled'

type TLinkTypeMap = LinkTypeMap<{ disabled?: boolean }>
type TLinkComponent = React.FC<TLinkProps> & OverridableComponent<TLinkTypeMap>
export type TLinkProps = TLinkTypeMap['props']

export const Link: TLinkComponent = forwardRef<HTMLAnchorElement, TLinkProps>(({ disabled = false, ...props }, ref) => (
  <$.Link ref={ref} disabled={disabled} aria-disabled={disabled ? 'true' : 'false'} {...props} />
)) as TLinkComponent

Link.displayName = 'Link'
