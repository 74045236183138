import { useState, FC } from 'react'
import { OutlinedInputProps } from '@mui/material/OutlinedInput'
import { FormControlProps } from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { PasswordRequirements } from './component/PasswordRequirements/PasswordRequirements'
import * as $ from './PasswordInput.styled'

type TInput = Pick<
  OutlinedInputProps,
  'value' | 'id' | 'label' | 'autoFocus' | 'onChange' | 'onFocus' | 'inputRef' | 'required'
>
type TFormControl = Pick<FormControlProps, 'size' | 'margin' | 'color'>
export interface IPasswordInputProps extends TInput, TFormControl {
  error?: boolean
  helperText?: string | string[]
  'data-test-id'?: string
  showPasswordRequirements?: boolean
}

export const PasswordInput: FC<IPasswordInputProps> = ({
  size,
  error,
  color,
  margin,
  helperText,
  'data-test-id': dataTestId = 'password-input',
  showPasswordRequirements = false,
  ...inputProps
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [value, setValue] = useState<Maybe<string>>(null)
  const [isFocused, setIsFocused] = useState(false)
  const isRequirementsVisible = Boolean(showPasswordRequirements && (isFocused || value || error))

  return (
    <$.FormControl
      data-test-id={dataTestId}
      size={size}
      margin={margin}
      fullWidth={true}
      color={color}
      variant="outlined"
      required={inputProps.required}
      error={error}
      isRequirementsVisible={isRequirementsVisible}
    >
      <InputLabel>{inputProps.label}</InputLabel>
      <$.Input
        {...inputProps}
        onChange={(e) => {
          inputProps?.onChange?.(e)
          setValue(e.target.value)
        }}
        onFocus={() => {
          setIsFocused(true)
        }}
        onBlur={() => {
          setIsFocused(false)
        }}
        autoComplete="true"
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <$.Button
              size="small"
              onClick={() => {
                setShowPassword(!showPassword)
              }}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </$.Button>
          </InputAdornment>
        }
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {isRequirementsVisible && <PasswordRequirements value={value} error={Boolean(error)} />}
    </$.FormControl>
  )
}
