const storage = new Map()

const getStorageItem = <T>(key: string): T | undefined => storage.get(key)
const setStorageItem = (key: string, value: unknown): void => {
  storage.set(key, value)
}

export const useStorage = () => ({
  setStorageItem,
  getStorageItem,
})
