// eslint-disable-next-line settle/preferred-modules
import { styled, Link as MuiLink } from '@mui/material'
import { youShallNotPass } from 'lib/helpers/youShallNotPass/youShallNotPass'
import type { TLinkProps } from './Link'

export const Link = styled(MuiLink, {
  shouldForwardProp: youShallNotPass('disabled'),
})<TLinkProps>(({ theme, disabled }) => ({
  ...(disabled && {
    color: theme.tokens.ThemeColorCommonOnSurfaceTertiary,
    pointerEvents: 'none',
    touchAction: 'none',
  }),
}))
