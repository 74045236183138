import { styled, TextField as MuiTextField } from '@mui/material'
import { youShallNotPass } from 'lib/helpers/youShallNotPass/youShallNotPass'

interface ITextField {
  warning?: boolean
}

export const TextField = styled(MuiTextField, {
  shouldForwardProp: youShallNotPass('warning'),
})<ITextField>(({ theme, warning }) => ({
  ...(warning && {
    '& .MuiOutlinedInput-root:not(.Mui-focused):not(.Mui-disabled)': {
      '& fieldset, &:hover fieldset': {
        borderColor: theme.tokens.ThemeColorCommonWarning,
      },
    },
    '& .MuiFormHelperText-root': {
      color: theme.tokens.ThemeColorCommonWarning,
    },
    '& label': {
      color: theme.tokens.ThemeColorCommonWarning,
    },
  }),
}))
