import { shallow } from 'zustand/shallow'
import { useSnackbarsStore } from '../useSnackbarsStore/useSnackbarsStore'

export const useSnackbars = () =>
  useSnackbarsStore(
    ({
      showAlert,
      showError,
      showWarning,
      showInfo,
      showApolloError,
      showApolloOrTextError,
      showSnackbar,
      hideSnackbar,
    }) => ({
      showAlert,
      showError,
      showWarning,
      showInfo,
      showApolloError,
      showApolloOrTextError,
      showSnackbar,
      hideSnackbar,
    }),
    shallow,
  )
