import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbars } from 'settle-ui/hooks'

export const useUrlBasedSnackbar = () => {
  const router = useRouter()
  const { showWarning, showError } = useSnackbars()
  const { t } = useTranslation()

  useEffect(() => {
    if (router.query.warning) {
      showWarning(router.query.warning.toString(), { id: 'url_based_warning' })

      delete router.query.warning
      router.push({ query: router.query })
    }
  }, [router, t, showWarning])

  useEffect(() => {
    if (router.query.error) {
      showError(router.query.error.toString(), { id: 'url_based_error' })

      delete router.query.error
      router.push({ query: router.query })
    }
  }, [router, t, showError])
}
