export const localStorageKeys = {
  businessId: 'business_id',
  invoiceDetailsVendorOCRDismissed: 'invoice_details_perspective_vendor_dismissed',
  plaidLinkToken: 'plaid_link_token',
  plaidPublicInvoiceUUID: 'plaid_public_invoice_uuid',
  plaidConnectionFlow: 'plaid_connection_flow',
  plaidOAuthRedirectUrl: 'plaid_oauth_redirect_url',
  payerReceivablesGroupOpen: 'payer_receivables_group_open',
  payerPayablesGroupOpen: 'payer_payables_group_open',
  vendorReceivablesGroupOpen: 'vendor_receivables_group_open',
  desktopNavigationDrawerOpen: 'desktop_navigation_drawer_open',
  searchByVendorNameSuggestions: 'search_by_vendor_name_suggestions',
  tableColumns: {
    vendors: 'vendors_table_columns',
    purchasers: 'purchasers_table_columns',
    payables: {
      all: 'columns_all',
      scheduled: 'columns_scheduled',
      unscheduled: 'columns_unscheduled',
      trash: 'columns_trash',
      paid: 'columns_paid',
      inReview: 'columns_in_review',
      approved: 'columns_approved',
      rejected: 'columns_rejected',
    },
    purchaseOrders: 'purchase_orders_table_columns',
    receipts: 'receipts_table_columns',
    payments: {
      all: 'payments_columns_all',
      scheduled: 'payments_columns_scheduled',
      initiated: 'payments_columns_initiated',
      paid: 'payments_columns_paid',
      failed: 'payments_columns_failed',
      inReview: 'payments_columns_in_review',
      canceled: 'payments_columns_canceled',
    },
  },
  dataGridState: {
    vendors: 'vendors_data_grid_state',
    purchasers: 'purchasers_data_grid_state',
    receivables: 'receivables_data_grid_state',
    clientManagerTeamMembers: 'client-manager-team-members',
    clientManagerClients: 'client-manager-clients',
  },
  dashboardWidgetsExpanded: 'dashboard_widgets_expanded',
  receivableSyncMessageDismissed: 'receivable_sync_message_dismissed',
  tabs: {
    receivables: 'receivables_visible_tabs',
    payables: 'payables_visible_tabs',
    payments: 'payments_visible_tabs',
  },
  processingReportsStatusSnackbar: 'processing_reports_status_snackbar',
  mfaToken: 'mfa_token',
  mfaEmail: 'mfa_email',
  hideOpenInQBOWarn: 'hide_open_in_qbo_warn',
  redirectToPathAfterAccountingConnection: 'redirect_to_path_after_accounting_connection',
  paymentPaginationPage: 'payment_pagination_page',
  payablesPaginationPage: 'payables_pagination_page',
  payablesPaginationVariables: 'payables_pagination_variables',
  payablesNavigationWithinPage: 'payables_navigation_within_page',
  receivablesPaginationPage: 'receivables_pagination_page',
  receivablesPaginationVariables: 'payables_pagination_variables',
  vendorCreditsNavigationWithinPage: 'vendor_credits_navigation_within_page',
  hasLendingJourneyToDos: 'has_lending_journey_to_dos',
  hasBizDetailsSubmitted: 'has_biz_details_submitted',
  displayReferralNavItem: 'display_referral_nav_item',
} as const

export const sessionStorageKeys = {
  isDemoScheduled: 'is_demo_scheduled',
  doNotShowFinNudgesBanner: 'do_not_show_financial_nudegs_banner',
}
