import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbars } from 'settle-ui/hooks'
import { externalLinks } from 'lib/consts/externalLinks'

export const useExistingVendorWarning = () => {
  const router = useRouter()
  const { showWarning } = useSnackbars()
  const { t } = useTranslation()

  useEffect(() => {
    if (router.query.existing_vendor === 'true') {
      showWarning(t('vendorBusinessAlreadyExists.title'), {
        id: 'login-page_business-exists-warning-text',
        text: t('vendorBusinessAlreadyExists.button'),
        onClick: () => open(`mailto:${externalLinks.supportEmail}`),
      })

      delete router.query.existing_vendor
      router.push({ query: router.query })
    }
  }, [router, t, showWarning])
}
