// eslint-disable-next-line import/no-named-as-default
import Router from 'next/router'
import { useApolloClient } from '@apollo/client'
import { logEvent, resetTracking } from 'lib/tracking'
import { ERouterPage } from 'lib/navigation'
import * as events from './useLogout.events'

export const useLogout = () => {
  const client = useApolloClient()

  return () => {
    logEvent(events.SIGNED_OUT)
    resetTracking()
    client.stop()
    client.clearStore()
    return Router.push(ERouterPage.login)
  }
}
